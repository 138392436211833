.helath-details-button {
    background: url("/assets/images/CloseSettings.svg") no-repeat 0;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    position: fixed;
    top: 22%;
    right: 18%;
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .helath-details {
        top: 15% !important;
    }
    .helath-details-button {
        top: 15% !important;
    }
    .health-topbar {
        display: flex;
        padding: 15px;
        justify-content: center;
        align-items: center;
        margin-top: 12%;
        z-index: 1;
    }
    .health-progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 50%;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        display: inline;
        z-index: 1;
        justify-content: center;
    }
    .star-img {
        margin-left: 2%;
        z-index: 1;
    }
    .health-detail-item {
        height: 15px;
        width: 3.35%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .health-container {
        display: flex;
        height: 100%;
        width: 48%;
        align-items: center;
        justify-content: center;
    }
    .health-div-list {
        display: flex;
        margin-top: 1%;
        flex-direction: column;
        padding: 5%;
    }
    .health-details-list {
        text-align: initial;
        width: 100%;
        margin-top: 2%;
    }
    .health-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .health-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-top: 17%;
        text-align: left;
    }
    .health-msg {
        margin-top: 0px;
        color: #8E8E8E;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .helath-details {
        top: 15% !important;
    }
    .helath-details-button {
        top: 13% !important;
    }
    .health-topbar {
        display: flex;
        height: 9vh;
        padding: 6px;
        justify-content: center;
        align-items: center;
        margin-top: 12%;
        z-index: 1;
    }
    .health-progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 50%;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        display: inline;
        z-index: 1;
    }
    .star-img {
        margin-left: 2%;
        z-index: 1;
    }
    .health-detail-item {
        height: 15px;
        width: 3.55%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .health-container {
        display: flex;
        height: 100%;
        width: 48%;
        align-items: center;
        justify-content: center;
    }
    .health-div-list {
        display: flex;
        margin-top: 1%;
        flex-direction: column;
        padding: 5%;
    }
    .health-details-list {
        text-align: initial;
        width: 100%;
        margin-top: 2%;
    }
    .health-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .health-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-top: 15%;
        text-align: left;
    }
    .health-msg {
        margin-top: 2%;
        color: #8E8E8E;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    .helath-details {
        top: 15% !important;
    }
    .helath-details-button {
        top: 13% !important;
    }
    .health-topbar {
        display: flex;
        height: 9vh;
        padding: 6px;
        justify-content: center;
        align-items: center;
        margin-top: 12%;
        z-index: 1;
    }
    .health-progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 35%;
        display: inline;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        z-index: 1;
    }
    .star-img {
        margin-left: 1%;
        z-index: 1;
    }
    .health-detail-item {
        height: 15px;
        width: 3.40%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .health-container {
        display: flex;
        height: 100%;
        width: 48%;
        align-items: center;
        justify-content: center;
    }
    .health-div-list {
        display: flex;
        margin-top: 1%;
        flex-direction: column;
        padding: 5%;
    }
    .health-details-list {
        text-align: initial;
        width: 100%;
        margin-top: 2%;
    }
    .health-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .health-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-top: 17%;
        text-align: left;
    }
    .health-msg {
        margin-top: 0px;
        color: #8E8E8E;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .helath-details {
        top: 15% !important;
    }
    .helath-details-button {
        background-size: 40px 40px;
        width: 40px;
        height: 40px;
        top: 23%;
        right: 18.5%;
    }
    .health-topbar {
        display: flex;
        height: 5vh;
        padding: 7px;
        justify-content: center;
        align-items: center;
        margin-top: 17%;
        z-index: 1;
    }
    .health-progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 35%;
        display: inline;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        z-index: 1;
    }
    .star-img {
        margin-left: 1%;
        z-index: 1;
    }
    .health-detail-item {
        height: 15px;
        width: 3.7%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .health-container {
        display: flex;
        height: 100%;
        width: 48%;
        align-items: center;
        justify-content: center;
    }
    .health-div-list {
        display: flex;
        margin-top: 1%;
        flex-direction: column;
        padding: 5%;
    }
    .health-details-list {
        text-align: initial;
        width: 100%;
        margin-top: 2%;
    }
    .health-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .health-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-top: 28%;
        text-align: left;
    }
    .health-msg {
        margin-top: 0px;
        color: #8E8E8E;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .helath-details-button {
        top: 22% !important;
    }
    .helath-details {
        top: 25% !important;
    }
    .health-topbar {
        display: flex;
        height: 8vh;
        padding: 7px;
        justify-content: center;
        align-items: center;
        margin-top: 15%;
        z-index: 1;
    }
    .star-img {
        margin-left: 1%;
        z-index: 1;
    }
    .health-progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 35%;
        display: inline;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        z-index: 1;
    }
    .health-detail-item {
        height: 15px;
        width: 4%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .health-container {
        display: flex;
        height: 100%;
        width: 48%;
        justify-content: center;
        align-items: center;
    }
    .health-div-list {
        display: flex;
        margin-top: 1%;
        flex-direction: column;
        padding: 5%;
    }
    .health-details-list {
        text-align: initial;
        width: 100%;
    }
    .health-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .health-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 13%;
        text-align: left;
    }
    .health-msg {
        margin-top: 2%;
        color: #8E8E8E;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0.5px;
        line-height: 18px;
    }
}


/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .health-topbar {
        margin-top: 28% !important;
    }
    .helath-details {
        top: 32% !important;
        height: 28vh !important;
    }
    .helath-details-button {
        top: 30.5% !important;
    }
    .health-name {
        margin-top: 15%;
    }
}


/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .health-topbar {
        margin-top: 28% !important;
    }
    .helath-details {
        top: 32% !important;
        height: 28vh !important;
    }
    .helath-details-button {
        top: 30.5% !important;
    }
    .health-name {
        margin-top: 15%;
    }
}


/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .health-topbar {
        margin-top: 27% !important;
    }
    .helath-details {
        top: 32% !important;
        height: 28vh !important;
    }
    .helath-details-button {
        top: 30.5% !important;
    }
}

.helath-details {
    background: linear-gradient(180deg, #656565 0%, #3D3D3D 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43), 0 10px 13px 0 rgba(35, 35, 35, 0.38);
    position: absolute;
    top: 25%;
    left: 20%;
    right: 20%;
    border-radius: 8px;
}

.health-details-screen {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    background: radial-gradient(circle, rgba(27, 27, 27, 0.74) 0%, rgba(27, 27, 27, 0.74) 100%);
    z-index: 3;
    color: #ffffff;
}