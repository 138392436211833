.App {
  text-align: center;
  background: #191c1d;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: "CircularStd-Book";
}

.app-tour-cs {
  background-color: none;
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
  .disabled-t-logo {
    height: 10vh;
    width: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 5%;
    background-image: radial-gradient(circle, #434748 0%, #202426 100%);
    border: 3px solid #161819;
    border-radius: 50%;
    box-shadow: 0 0 0px 4px #202426;
    color: #ff590d;
    animation: fadeIn;
    box-shadow: 0 0 7px 0 #434748;
  }
  .enabled-t-logo {
    height: 10vh;
    width: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 3.5%;
    bottom: 5.5%;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    box-shadow: inset 0px -3px 3px 3px #de4d00;
    color: #fff;
    box-shadow: 0 0 7px 0 #434748;
  }
  .enabled-tour-t-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 5%;
    bottom: 3%;
    border-radius: 50%;
    color: #fff;
    border: 2.5px solid #ff590d;
    opacity: 0.92;
    box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
    padding: 1px;
  }
  .t-character {
    font-size: 1.5rem;
    height: 9vh;
    width: 9vh;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    font-family: "CircularStd-Book";
  }
  .t-disabled-character {
    font-size: 1.5rem;
    height: 9vh;
    width: 9vh;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    font-family: "CircularStd-Book";
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .disabled-t-logo {
    height: 10vh;
    width: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 5%;
    background-image: radial-gradient(circle, #434748 0%, #202426 100%);
    border: 3px solid #161819;
    border-radius: 50%;
    box-shadow: 0 0 0px 4px #202426;
    color: #ff590d;
    animation: fadeIn;
    box-shadow: 0 0 7px 0 #434748;
  }
  .enabled-t-logo {
    height: 10vh;
    width: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    left: 3.5%;
    bottom: 5.5%;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    box-shadow: inset 0px -3px 3px 3px #de4d00;
    color: #fff;
  }
  .enabled-tour-t-logo {
    display: flex;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 5%;
    border-radius: 50%;
    color: #fff;
    border: 2.5px solid #ff590d;
    opacity: 0.92;
    box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
  }
  .t-character {
    font-size: 1.4rem;
    margin: 0.7vh;
    height: 8.6vh;
    width: 8.6vh;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
  .t-disabled-character {
    font-size: 1.4rem;
    margin: 0.7vh;
    height: 8.6vh;
    width: 8.6vh;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .disabled-t-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13vh;
    width: 13vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 6%;
    background-image: radial-gradient(circle, #434748 0%, #202426 100%);
    border: 3px solid #161819;
    border-radius: 50%;
    box-shadow: 0 0 0px 4px #202426;
    color: #ff590d;
    animation: fadeIn;
    box-shadow: 0 0 7px 0 #434748;
  }
  .enabled-t-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13vh;
    width: 13vh;
    z-index: 2;
    position: absolute;
    left: 3.5%;
    bottom: 6.5%;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    box-shadow: inset 0px -3px 3px 3px #de4d00;
    color: #fff;
  }
  .enabled-tour-t-logo {
    display: flex;
    height: 13vh;
    width: 13vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 5%;
    border-radius: 50%;
    color: #fff;
    border: 2.5px solid #ff590d;
    opacity: 0.92;
    box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
  }
  .t-character {
    font-size: 2rem;
    margin: 0.7vh;
    height: 11.6vh;
    width: 11.6vh;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
  .t-disabled-character {
    font-size: 2rem;
    margin: 0.7vh;
    height: 11.6vh;
    width: 11.6vh;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .disabled-t-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 5%;
    background-image: radial-gradient(circle, #434748 0%, #202426 100%);
    border: 3px solid #161819;
    border-radius: 50%;
    box-shadow: 0 0 0px 4px #202426;
    color: #ff590d;
    animation: fadeIn;
    box-shadow: 0 0 7px 0 #434748;
  }
  .enabled-t-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 5%;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    box-shadow: inset 0px -3px 3px 3px #de4d00;
    color: #fff;
  }
  .enabled-tour-t-logo {
    display: flex;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 5%;
    border-radius: 50%;
    color: #fff;
    border: 2.5px solid #ff590d;
    opacity: 0.92;
    box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
  }
  .t-character {
    font-size: 3rem;
    margin: 0.5vh;
    height: 9vh;
    width: 9vh;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
  .t-disabled-character {
    font-size: 3rem;
    margin: 0.5vh;
    height: 9vh;
    width: 9vh;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  .disabled-t-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 3%;
    background-image: radial-gradient(circle, #434748 0%, #202426 100%);
    border: 3px solid #161819;
    border-radius: 50%;
    box-shadow: 0 0 0px 4px #202426;
    color: #ff590d;
    animation: fadeIn;
    box-shadow: 0 0 7px 0 #434748;
  }
  .enabled-t-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 3%;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    box-shadow: inset 0px -3px 3px 3px #de4d00;
    color: #fff;
  }
  .enabled-tour-t-logo {
    display: flex;
    height: 10vh;
    width: 10vh;
    z-index: 2;
    position: absolute;
    left: 3%;
    bottom: 3%;
    border-radius: 50%;
    color: #fff;
    border: 2.5px solid #ff590d;
    opacity: 0.92;
    box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
  }
  .t-character {
    font-size: 2.4rem;
    margin: 0.5vh;
    height: 9vh;
    width: 9vh;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
  .t-disabled-character {
    font-size: 2.4rem;
    margin: 0.5vh;
    height: 9vh;
    width: 9vh;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "CircularStd-Book";
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .t-character {
    font-size: 3rem;
  }
  .t-disabled-character {
    font-size: 3rem;
  }
}

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .t-character {
    font-size: 3rem;
  }
  .t-disabled-character {
    font-size: 3rem;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .t-character {
    font-size: 3.4rem;
  }
  .t-disabled-character {
    font-size: 3.4rem;
  }
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
