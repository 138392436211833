.param-close {
  /* background: url("/assets/images/param-close.svg") no-repeat; */
  /* float: right; */
  /* width: 10%; */
  /* height: 17%; */
  position: absolute;
  left: 93%;
  top: -13%;
}

.tables {
  text-align: left;
  padding: 8px;
  font-size: 0.6rem;
}

.low-ROE {
  color: red;
}
