/* Extra small devices (phones, 600px and down) */
.badge {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .app-tour-badge {
    width: 106px;
    height: 9%;
    margin-top: -1.5px;
  }
  .badge {
    height: 35px;
    width: 35px;
    margin-top: 1px;
  }
  .envoy {
    background: url("/assets/images/envoy_badge.svg") no-repeat 0;
    background-size: cover;
    background-position: center;
  }
  .envoy-locked {
    background: url("/assets/images/envoy_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .istio {
    background: url("/assets/images/istio_badge.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .istio-locked {
    background: url("/assets/images/istio_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .TSB {
    background: url("/assets/images/tsb_badge.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .TSB-locked {
    background: url("/assets/images/tsb_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .badge-bar {
    display: flex;
    height: 100%;
    width: 48%;
    align-items: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .app-tour-badge {
    width: 107px;
    height: 35px;
  }
  .badge {
    height: 35px;
    width: 35px;
    margin-top: 1px;
  }
  .envoy {
    background: url("/assets/images/envoy_badge.svg") no-repeat 0;
    background-size: cover;
    background-position: center;
  }
  .envoy-locked {
    background: url("/assets/images/envoy_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .istio {
    background: url("/assets/images/istio_badge.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .istio-locked {
    background: url("/assets/images/istio_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .TSB {
    background: url("/assets/images/tsb_badge.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .TSB-locked {
    background: url("/assets/images/tsb_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .badge-bar {
    display: flex;
    height: 100%;
    width: 48%;
    align-items: center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .app-tour-badge {
    width: 107px;
    height: 37px;
    margin-top: 0px;
  }
  .badges {
    width: 8%;
    background: url("/assets/images/BadgeLocked.svg") no-repeat;
    background-size: cover;
  }
  .badge-bar {
    display: flex;
    height: 100%;
    width: 48%;
    align-items: center;
  }
  .badge {
    height: 35px;
    width: 35px;
    margin-top: 3px;
  }
  .envoy {
    background: url("/assets/images/envoy_badge.svg") no-repeat 0;
    background-size: cover;
    background-position: center;
  }
  .envoy-locked {
    background: url("/assets/images/envoy_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .istio {
    background: url("/assets/images/istio_badge.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .istio-locked {
    background: url("/assets/images/istio_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .TSB {
    background: url("/assets/images/tsb_badge.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .TSB-locked {
    background: url("/assets/images/tsb_badge_locked.svg") no-repeat;
    background-size: cover;
    background-position: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .app-tour-badge {
    width: 170px;
    height: 57px;
    margin-top: 0px;
  }
  .badge {
    height: 55px;
    width: 57px;
    margin-right: 0px;
  }
  .envoy {
    background: url("/assets/images/envoy_badge.svg") no-repeat 0;
    background-size: cover;
  }
  .envoy-locked {
    background: url("/assets/images/envoy_badge_locked.svg") no-repeat;
    background-size: cover;
  }
  .istio {
    background: url("/assets/images/istio_badge.svg") no-repeat;
    background-size: cover;
  }
  .istio-locked {
    background: url("/assets/images/istio_badge_locked.svg") no-repeat;
    background-size: cover;
  }
  .TSB {
    background: url("/assets/images/tsb_badge.svg") no-repeat;
    background-size: cover;
  }
  .TSB-locked {
    background: url("/assets/images/tsb_badge_locked.svg") no-repeat;
    background-size: cover;
  }
  .badge-bar {
    display: flex;
    height: 100%;
    width: 48%;
    align-items: center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  .app-tour-badge {
    width: 170px;
    height: 44px;
    margin-top: 0px;
  }
  .badge {
    height: 55px;
    width: 57px;
    margin-right: 0px;
  }
  .envoy {
    background: url("/assets/images/envoy_badge.svg") no-repeat 0;
    background-size: cover;
  }
  .envoy-locked {
    background: url("/assets/images/envoy_badge_locked.svg") no-repeat;
    background-size: cover;
  }
  .istio {
    background: url("/assets/images/istio_badge.svg") no-repeat;
    background-size: cover;
  }
  .istio-locked {
    background: url("/assets/images/istio_badge_locked.svg") no-repeat;
    background-size: cover;
  }
  .TSB {
    background: url("/assets/images/tsb_badge.svg") no-repeat;
    background-size: cover;
  }
  .TSB-locked {
    background: url("/assets/images/tsb_badge_locked.svg") no-repeat;
    background-size: cover;
  }
  .badge-bar {
    display: flex;
    height: 100%;
    width: 48%;
    align-items: center;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .app-tour-badge {
    width: 170px;
    height: 48px;
    margin-top: 0px;
  }
}

.app-tour-badge {
  position: absolute;
  border: 2.5px solid #ff590d;
  opacity: 0.92;
  box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
  border-radius: 50px;
  left: 2.4%;
}

.badge-animate {
  animation: badge-animation 3s forwards;
}

@keyframes badge-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
