/* Extra small devices (phones, 600px and down) */

.progress-container {
    cursor: pointer;
}

.service-health-text {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .app-tour-health {
        width: 45%;
        position: absolute;
        border: 2.5px solid #ff590d;
        opacity: 0.92;
        box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
        height: 6%;
        border-radius: 50px;
        right: 0%;
        margin-left: auto;
        background: transparent;
    }
    .block {
        display: block;
    }
    .mbq {
        margin-bottom: 0.25rem;
    }
    .mb1 {
        margin-bottom: 1rem;
    }
    .container {
        display: flex;
        height: 100%;
        width: 48%;
        flex-direction: row-reverse;
        align-items: center;
    }
    .progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 50%;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        display: inline;
        margin-right: 1.5%;
    }
    .health-item {
        height: 15px;
        width: 3.35%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .service-health-text {
        margin-right: 3%;
        opacity: 0.9;
        color: #ffffff;
        font-size: 0.6rem;
    }
    /* .star-img {
    margin-left: 2%;
  } */
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .app-tour-health {
        width: 42.5%;
        width: -webkit-calc(26% + 110px);
        width: -moz-calc(26% + 110px);
        width: calc(26% + 110px);
        position: absolute;
        border: 2.5px solid #ff590d;
        opacity: 0.92;
        box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
        height: 6%;
        border-radius: 50px;
        right: 0%;
        margin-left: auto;
        background: transparent;
    }
    .block {
        display: block;
    }
    .mbq {
        margin-bottom: 0.25rem;
    }
    .mb1 {
        margin-bottom: 1rem;
    }
    .container {
        display: flex;
        height: 100%;
        width: 48%;
        flex-direction: row-reverse;
        align-items: center;
    }
    .progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 50%;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        display: inline;
        margin-right: 1.5%;
    }
    .health-item {
        height: 15px;
        width: 3.55%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .service-health-text {
        margin-right: 3%;
        opacity: 0.9;
        color: #ffffff;
        font-size: 0.6rem;
    }
    /* .star-img {
    margin-left: 2%;
  } */
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .app-tour-health {
        width: 33.5%;
        width: -webkit-calc(19.5% + 127px);
        width: -moz-calc(19.5% + 127px);
        width: calc(19.5% + 127px);
        position: absolute;
        z-index: 1;
        border: 2.5px solid #ff590d;
        opacity: 0.92;
        box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
        height: 6%;
        border-radius: 50px;
        right: 0%;
        margin-left: auto;
        background: transparent;
    }
    .container {
        display: flex;
        height: 100%;
        width: 48%;
        flex-direction: row-reverse;
        align-items: center;
    }
    .progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 35%;
        display: inline;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        margin-right: 1.5%;
    }
    .service-health-text {
        margin-right: 2%;
        opacity: 0.9;
        color: #ffffff;
        font-size: 0.8rem;
    }
    /* .star-img {
    margin-left: 1%;
  } */
    .health-item {
        height: 15px;
        width: 3.4%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .app-tour-health {
        width: 32.5%;
        width: -webkit-calc(26% + 110px);
        width: -moz-calc(26% + 110px);
        width: calc(19.5% + 127px);
        position: absolute;
        border: 2.5px solid #ff590d;
        opacity: 0.92;
        box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
        height: 6%;
        border-radius: 50px;
        right: 0%;
        margin-left: auto;
        background: transparent;
    }
    .container {
        display: flex;
        height: 100%;
        width: 48%;
        flex-direction: row-reverse;
        align-items: center;
    }
    .progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 35%;
        display: inline;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        margin-right: 1.5%;
    }
    .service-health-text {
        margin-right: 2%;
        opacity: 0.9;
        color: #ffffff;
        font-size: 0.8rem;
    }
    .health-item {
        height: 15px;
        width: 3.7%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .app-tour-health {
        /* width: 29%; */
        width: 32.5%;
        width: -webkit-calc(26% + 110px);
        width: -moz-calc(26% + 110px);
        width: calc(19.5% + 127px);
        position: absolute;
        border: 2.5px solid #ff590d;
        opacity: 0.92;
        box-shadow: 0 0 7px 0 rgba(255, 89, 13, 0.65);
        height: 6%;
        border-radius: 50px;
        right: 0%;
        margin-left: auto;
        background: transparent;
    }
    .block {
        display: block;
    }
    .mbq {
        margin-bottom: 0.25rem;
    }
    .mb1 {
        margin-bottom: 1rem;
    }
    .container {
        display: flex;
        height: 100%;
        width: 48%;
        flex-direction: row-reverse;
        align-items: center;
    }
    .progress-container {
        background: #202426;
        box-shadow: 0 0 4px 2px rgba(95, 95, 95, 0.72);
        height: 18px;
        width: 35%;
        display: inline;
        align-items: center;
        padding-left: 3px;
        border-radius: 10px;
        padding-right: 3px;
        margin-right: 1%;
    }
    .health-item {
        height: 15px;
        width: 4%;
        background-color: red;
        margin: 1px;
        border-radius: 10px;
        display: inline;
        float: left;
    }
    .service-health-text {
        margin-right: 2%;
        opacity: 0.9;
        color: #ffffff;
        font-size: 0.8rem;
    }
}

.star-img {
    position: absolute;
    right: 1%;
}