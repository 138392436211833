/* Landscape */

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .start-options-container {
    bottom: 20% !important;
  }
}

/* iPhone XR */

@media only screen and (device-width: 896px) and (device-height: 414px) and (-webkit-device-pixel-ratio: 2) {
  .start-options-container {
    bottom: 20% !important;
  }
}

/* iPhone XS */

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .start-options-container {
    bottom: 20% !important;
  }
}

.start-options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 50px;
  position: fixed;
  right: 50px;
  bottom: 6%;
}

.start-background {
  background: url("/assets/images/BG_Mesh.svg") no-repeat 0;
  flex: 1;
  padding: 0px;
  align-items: center;
  align-content: center;
  background-size: 100vw 100vh;
  height: 100vh;
}

.start-action-button {
  align-self: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 180px;
  /* background: url("/assets/images/start-btn-bg.svg") no-repeat 0; */
  border-radius: 30px;
  background: linear-gradient(0deg, #ff590d 0%, #ffc977 91%, #fffefc 100%);
  background-position: center;
  flex-direction: row;
}

.start-button-title {
  color: #ffffff;
  font-size: 1rem;
  width: 180px;
  text-align: center;
  font-family: "CircularStd-Book";
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 25px;
  padding-top: 3.5px;
}

.setting-button-bg {
  background: url("/assets/images/settings-circle.svg") no-repeat 0;
  background-size: 50px 50px;
  height: 50px;
  width: 50px;
}

.info-btn-size {
  background-position: 50% !important;
  height: 50px;
  width: 50px;
}

.setting-button {
  background: url("/assets/images/Settings.svg") no-repeat 0;
  background-size: 30px 30px !important;
}

.info-button {
  background: url("/assets/images/badge_info.svg") no-repeat 0;
  background-size: 30px 30px !important;
  cursor: pointer;
}

.music-on {
  background: url("/assets/images/play.svg") no-repeat 0;
  background-size: 50px 50px !important;
  cursor: pointer;
}

.music-off {
  background: url("/assets/images/pause.svg") no-repeat 0;
  background-size: 50px 50px !important;
  cursor: pointer;
}

.volume-on {
  background: url("/assets/images/volume.svg") no-repeat 0;
  background-size: 50px 50px !important;
  cursor: pointer;
}

.volume-off {
  background: url("/assets/images/volumeoff.svg") no-repeat 0;
  background-size: 50px 50px !important;
  cursor: pointer;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
}

.settings-button {
  cursor: pointer;
  /* background: url("/assets/images/btn-bg.svg") no-repeat 0; */
  border-radius: 30px;
  background: linear-gradient(0deg, #ff590d 0%, #ffc977 91%, #fffefc 100%);
  height: 54.76px;
  width: 230px;
  border-radius: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
