.noSelect {
    cursor: pointer;
}

.message {
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1rem;
    letter-spacing: 0.5px;
    line-height: 15px;
    align-self: center;
}

.arrow-up {
    background-position: 100%;
    height: 40px;
    width: 42px;
    position: fixed;
    top: 15px;
    right: 48%;
}

.arrow-up-left {
    background-position: 100%;
    height: 40px;
    width: 42px;
    position: fixed;
}

@media only screen and (max-width: 600px) {
    .arrow-up-left {
        top: 15px;
        left: 130px;
        left: -webkit-calc(2.4% + 112px);
        left: -moz-calc(2.4% + 112px);
        left: calc(2.4% + 112px);
    }
    .step-one-container {
        display: flex;
        flex-direction: row;
    }
    .fixed-position-one {
        display: flex;
        height: 15vh;
        position: absolute;
        left: 8%;
        bottom: 17%;
        bottom: -webkit-calc(2.5% + 10vh);
        bottom: -moz-calc(2.5% + 10vh);
        bottom: calc(2.5% + 10vh);
    }
    .fixed-position-three {
        position: fixed;
        top: 4vh;
        left: 0;
        width: 77%;
        margin-left: 15%;
    }
    .fixed-position-two {
        position: fixed;
        top: 200;
        left: 20%;
        width: 75%;
    }
    .step-two-container {
        display: flex;
        flex-direction: row;
        margin: 30px 0px 0px 0px;
    }
    .message {
        font-size: 0.9rem !important;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .step-one-container {
        display: flex;
        flex-direction: row;
    }
    .fixed-position-one {
        display: flex;
        height: 10vh;
        position: absolute;
        left: 7%;
        bottom: 17%;
        bottom: -webkit-calc(6.5% + 10vh);
        bottom: -moz-calc(6.5% + 10vh);
        bottom: calc(6.5% + 10vh);
    }
    .fixed-position-three {
        position: fixed;
        top: 4vh;
        left: 0;
        width: 55%;
        margin-left: 15%;
    }
    .arrow-up-left {
        margin-top: 0px;
        left: 130px;
        left: -webkit-calc(2.4% + 112px);
        left: -moz-calc(2.4% + 112px);
        left: calc(2.4% + 112px);
    }
    .arrow-up {
        top: 20px;
        right: 44%;
        right: -webkit-calc(24% + 130px);
        right: -moz-calc(24% + 130px);
        right: calc(24% + 130px);
    }
    .message {
        font-size: 0.9rem !important;
    }
    .fixed-position-two {
        position: fixed;
        top: 200;
        left: 15%;
        width: 75%;
    }
    .step-two-container {
        display: flex;
        flex-direction: row;
        margin: 30px 0px 0px 0px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .step-one-container {
        display: flex;
        flex-direction: row;
    }
    .fixed-position-one {
        display: flex;
        height: 13vh;
        position: absolute;
        left: 6.5%;
        bottom: 17%;
        bottom: -webkit-calc(6.5% + 10vh);
        bottom: -moz-calc(6.5% + 10vh);
        bottom: calc(6.5% + 10vh);
    }
    .fixed-position-three {
        position: fixed;
        top: 4vh;
        left: 0;
        width: 55%;
        margin-left: 10%;
    }
    .arrow-up-left {
        margin-top: 0px;
        left: 130px;
        left: -webkit-calc(2.4% + 112px);
        left: -moz-calc(2.4% + 112px);
        left: calc(2.4% + 112px);
    }
    .arrow-up {
        top: 15px;
        right: 36%;
        right: -webkit-calc(17.5% + 150px);
        right: -moz-calc(17.5% + 150px);
        right: calc(17.5% + 150px);
    }
    .fixed-position-two {
        position: fixed;
        top: 4vh;
        right: 0;
        width: 70%;
        margin-right: 5%;
    }
    .step-two-container {
        display: flex;
        flex-direction: row;
        margin: 45px 0px 0px 0px;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .step-one-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .fixed-position-one {
        display: flex;
        height: 5vh;
        position: absolute;
        left: 6.5%;
        bottom: 16%;
        bottom: -webkit-calc(6.5% + 10vh);
        bottom: -moz-calc(6.5% + 10vh);
        bottom: calc(6.5% + 10vh);
    }
    .fixed-position-three {
        position: fixed;
        top: 3.5vh;
        left: 0;
        width: 50%;
        margin-left: 10%;
    }
    .arrow-up-left {
        left: 200px;
        left: -webkit-calc(2.4% + 175px);
        left: -moz-calc(2.4% + 175px);
        left: calc(2.4% + 175px);
    }
    .arrow-up {
        top: 25px;
        right: 32.5%;
        right: -webkit-calc(19.5% + 135px);
        right: -moz-calc(19.5% + 135px);
        right: calc(19.5% + 135px);
    }
    .fixed-position-two {
        position: fixed;
        top: 3.5vh;
        right: 0;
        width: 50%;
        margin-right: 5%;
        left: 30%;
    }
    .step-two-container {
        display: flex;
        flex-direction: row;
        margin: 50px 50px 0px 0px;
        padding-bottom: 30px;
    }
    .step-three-container {
        margin: 50px 0px 0px 0px !important;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .step-one-container {
        display: flex;
        flex-direction: row;
    }
    .fixed-position-one {
        display: flex;
        height: 9vh;
        position: absolute;
        left: 6%;
        bottom: 12%;
        bottom: -webkit-calc(1% + 10vh);
        bottom: -moz-calc(1% + 10vh);
        bottom: calc(1% + 10vh);
    }
    .fixed-position-three {
        position: fixed;
        top: 3vh;
        left: 0;
        width: 50%;
        margin-left: 10%;
    }
    .arrow-up-left {
        left: 200px;
        left: -webkit-calc(2.4% + 175px);
        left: -moz-calc(2.4% + 175px);
        left: calc(2.4% + 175px);
    }
    .arrow-up {
        top: 3.5vh !important;
        right: 29.5%;
        right: -webkit-calc(20% + 130px);
        right: -moz-calc(20% + 130px);
        right: calc(20% + 130px);
    }
    .fixed-position-two {
        position: fixed;
        top: 3.5vh;
        right: 0;
        width: 50%;
        margin-right: 5%;
        left: 50%;
    }
    .step-two-container {
        display: flex;
        flex-direction: row;
        margin: 40px 50px 0px 0px;
    }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .fixed-position-one {
        display: flex;
        height: 6vh;
        position: absolute;
        left: 7%;
        bottom: 12%;
    }
    .fixed-position-three {
        position: fixed;
        top: 3vh;
        left: 0;
        width: 50%;
        margin-left: 10%;
    }
    .arrow-up-left {
        left: 200px;
        left: -webkit-calc(2.4% + 175px);
        left: -moz-calc(2.4% + 175px);
        left: calc(2.4% + 175px);
    }
}

[data-tour-elem="controls"] {
    justify-content: center;
}

.CustomHelper__wrapper {
    display: block;
    position: sticky;
    height: 100%;
    background-color: transparent;
}

.CustomHelper__content {
    flex: 1;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    padding: 2rem;
    padding-bottom: 0rem;
}

.CustomHelper__navArrow {
    margin-right: 10px;
    margin-left: 10px;
    height: 50px !important;
    width: 50px !important;
    align-self: center;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.next-arrow {
    margin-right: 10px;
    margin-left: 10px;
    height: 50px !important;
    width: 50px !important;
    align-self: center;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
}

.skip-button-tour,
.skip-button-tour:active {
    border: 0px solid transparent;
    /* background: url("/assets/images/skip.svg") no-repeat 0; */
    border-radius: 30px;
    background: linear-gradient(0deg, #ff590d 0%, #ffc977 91%, #fffefc 100%);
    width: 112px;
    height: 40px;
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    right: 3%;
    bottom: 4.5%;
}

.navigation {
    /* margin-top: 20%; */
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
}

.done-button,
.done-button:active {
    border-radius: 30px;
    background: linear-gradient(0deg, #ff590d 0%, #ffc977 91%, #fffefc 100%);
    border: 0px solid transparent;
    width: 112px;
    height: 40px;
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    right: 3%;
    bottom: 4.5%;
}

.tour-count {
    box-sizing: border-box;
    height: 21.6px;
    width: 21.6px;
    border: 1.35px solid #ff590d;
    color: #ff590d;
    border-radius: 10.5px;
    font-family: "CircularStd-Book";
    font-size: 0.9rem;
    text-align: center;
    margin-right: 10px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-bg {
    background-position: 100%;
    height: 40px;
    width: 42px;
}

.tour {
    max-width: 100%;
}

.app-tour-cs {
    background-color: none;
}

.tour-1 {
    transform: rotate(290deg);
}

.step-three-container {
    display: flex;
    flex-direction: row;
    margin: 40px 0px 0px 0px;
}