/* Extra small devices (phones, 600px and down) */

.what-offering {
  color: #ffffff;
  font-family: "CircularStd-Book";
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 19px;
  text-align: left;
  width: 90%;
  margin-top: 5%;
}

.submit-button {
  align-self: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 180px;
  border-radius: 30px;
  background: linear-gradient(0deg, #ff590d 0%, #ffc977 91%, #fffefc 100%);
  /* background: url("/assets/images/start-btn-bg.svg") no-repeat 0; */
  background-position: center;
  background-size: cover;
  flex-direction: row;
  align-self: flex-start;
  margin-left: 5%;
  margin-top: 2%;
}

.submit-title {
  color: #ffffff;
  font-size: 1.4rem;
  width: 180px;
  text-align: center;
  font-family: "CircularStd-Book";
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 25px;
  padding-top: 3.5px;
}

@media only screen and (max-width: 600px) {
  .user-container {
    padding-top: 1% !important;
  }
  .user-challange {
    font-size: 1.1rem !important;
  }
  .user-accomplished {
    margin-top: 10px;
  }
  .user-details-msg {
    margin-top: 0% !important;
    margin-bottom: 0% !important;
  }
  .what-offering {
    margin-top: 1% !important;
    font-size: 0.9rem !important;
  }
  .input-container {
    margin-top: 1% !important;
    font-size: 0.9rem !important;
  }
  .submit-button {
    height: 40px;
    width: 150px;
    background-size: 150px 40px;
    margin-left: 4.5%;
    margin-top: 1%;
  }
  .submit-title {
    width: 150px;
    font-size: 1.2rem !important;
  }
  .rectangle-input {
    height: 30px !important;
  }
  .input-filed {
    height: 26px !important;
    font-size: 0.9rem !important;
  }
  .tetrate-service-brid {
    font-size: 0.9rem !important;
  }
  .tet-logo {
    padding-top: 0%;
    margin-top: 1%;
    background-size: 100px 60px;
    width: 100px;
    height: 60px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
  .user-container {
    padding-top: 1% !important;
  }
  .user-challange {
    font-size: 1.1rem !important;
  }
  .user-accomplished {
    margin-top: 10px;
  }
  .user-details-msg {
    margin-top: 1% !important;
    margin-bottom: 0% !important;
  }
  .what-offering {
    margin-top: 1% !important;
    font-size: 1rem !important;
  }
  .input-container {
    margin-top: 1% !important;
  }
  .submit-button {
    height: 40px;
    width: 150px;
    background-size: 150px 40px;
    margin-left: 4.5%;
    margin-top: 1%;
  }
  .submit-title {
    width: 150px;
    font-size: 1.2rem !important;
  }
  .rectangle-input {
    height: 30px !important;
  }
  .input-filed {
    height: 26px !important;
    font-size: 1rem !important;
  }
  .tetrate-service-brid {
    font-size: 1rem !important;
  }
  .tet-logo {
    padding-top: 0%;
    margin-top: 1%;
    background-size: 100px 60px;
    width: 100px;
    height: 60px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
  .user-accomplished {
  }
  .user-challange {
    font-size: 1.4rem !important;
  }
  .user-details-msg {
    margin-top: 1% !important;
    font-size: 1.1rem;
  }
  .subcription-bg {
    bottom: 30px;
    right: 50px;
  }
  .bridge-bg {
    bottom: 30px;
    left: 50px;
  }
  .what-offering {
    margin-top: 2% !important;
    font-size: 1rem !important;
  }
  .input-filed {
    height: 26px !important;
    font-size: 1rem !important;
  }
  .tetrate-service-brid {
    font-size: 1rem !important;
  }
  .tet-logo {
    padding-top: 0%;
    margin-top: 1%;
    background-size: 100px 70px;
    width: 100px;
    height: 70px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
  .user-accomplished {
  }
  .user-details-msg {
    margin-top: 8px;
  }
  .subcription-bg {
    bottom: 30px;
    right: 50px;
  }
  .bridge-bg {
    bottom: 30px;
    left: 50px;
  }
  .tet-logo {
    padding-top: 0%;
    margin-top: 2%;
    background-size: cover;
    width: 100px;
    height: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  .user-container {
    padding-top: 5% !important;
  }
  .user-challange {
    font-size: 2rem !important;
  }
  .user-accomplished {
  }
  .user-details-msg {
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    font-size: 1.3rem;
  }
  .what-offering {
    margin-top: 5% !important;
  }
  .submit-button {
    height: 50px !important;
    width: 180px !important;
    background-size: cover;
    margin-left: 5%;
    margin-top: 2%;
  }
  .submit-title {
    width: 180px !important;
    font-size: 1.4rem !important;
  }
  .rectangle-input {
    height: 40px !important;
  }
  .input-filed {
    height: 36px !important;
  }
  .tet-logo {
    padding-top: 0%;
    margin-top: 1%;
    background-size: 130px 130px !important;
    width: 130px !important;
    height: 130px !important;
  }
}

/* iPhone XR */

@media only screen and (device-width: 896px) and (device-height: 414px) and (-webkit-device-pixel-ratio: 2) {
  .user-container {
    padding-top: 5% !important;
  }
  .input-filed {
    height: 26px !important;
  }
}

/* iPhone XS */

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .user-container {
    padding-top: 4% !important;
  }
  .input-filed {
    height: 26px !important;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .user-container {
    padding-top: 10% !important;
  }
}

/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .user-container {
    padding-top: 10% !important;
  }
}

/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .user-container {
    padding-top: 10% !important;
  }
}

.user-background {
  background: url("/assets/images/BG_Mesh.svg") no-repeat;
  background-size: cover;
  height: 100vh;
  display: block;
  position: sticky;
  top: 0;
}

.user-container {
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-items: center;
  padding-top: 5%;
  position: sticky;
  top: 0;
}

.user-screen {
  height: 100vh;
  width: 100vw;
}

.thank-you-div {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: flex-start;
  align-items: left;
  margin-top: 19px;
  align-self: flex-start;
  margin-left: 5%;
}

.rectangle-input {
  width: 39vw;
  align-self: flex-start;
  margin-right: 2%;
  display: flex;
  flex: column;
  align-items: center;
  border: 2px solid #8e8e8e;
  border-radius: 25px;
  background-color: #282828;
  padding-left: 1.8%;
  padding-right: 1.8%;
  height: 40px;
}

.input-filed {
  width: 36vw;
  height: 38px;
  border: none;
  background-color: #282828;
  color: #ffffff;
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 19px;
}

.input-filed:focus {
  outline: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8e8e8e;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8e8e8e;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8e8e8e;
}

.checkbox-container {
  width: 39vw;
  align-self: flex-start;
  margin-right: 2%;
  display: flex;
  flex: column;
  height: 40px;
  align-items: center;
}

.unchecked {
  height: 26px;
  width: 26px;
  border: 2px solid #8e8e8e;
  border-radius: 3.75px;
  background-color: #282828;
  margin-right: 1.5%;
}

.checked {
  height: 30px;
  width: 30px;
  border-radius: 3.75px;
  background: linear-gradient(0deg, #ff590d 0%, #ffc977 100%);
  margin-right: 1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tetrate-service-brid {
  color: #ffffff;
  font-family: "CircularStd-Book";
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 19px;
  text-align: left;
}

.user-challange {
  color: #ffffff;
  font-family: "CircularStd-Book";
  font-size: 1.6rem;
  text-align: left;
  width: 90%;
}

.user-details-msg {
  color: #8e8e8e;
  font-family: "CircularStd-Book";
  /* font-size: 1rem; */
  letter-spacing: 0.16px;
  line-height: 14px;
  text-align: left;
  margin-top: 1%;
  width: 90%;
  margin-bottom: 1%;
}

.istio-check {
  padding-left: 3%;
}

.checked-img {
  margin-top: 10px;
}

.close-details-button {
  background: url("/assets/images/CloseSettings.svg") no-repeat 0;
  background-size: 50px 50px;
  width: 58px;
  height: 58px;
  position: fixed;
  top: 4%;
  right: 20px;
  cursor: pointer;
}

.tet-logo {
  background: url("/assets/images/aboutus-logo.svg") no-repeat 0;
  background-position: center;
  background-size: 100px 70px;
  width: 100px;
  height: 70px;
  display: inline-block;
}
