/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .aboutus-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        padding-inline-start: 0%;
    }
    .connect {
        margin: 0px 50px 0px 50px;
        padding-bottom: 0px;
    }
    .policy {
        margin: 0px 50px 0px 50px;
    }
    .aboutus-button {
        height: 36px;
        width: 190px;
        margin-bottom: 1%;
    }
    .aboutus {
        padding-top: 0%;
        margin-top: 1%;
        background-size: 100px 60px;
        width: 100px;
        height: 60px;
    }
    .music-credit {
        margin-top: 0% !important;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    .aboutus-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        padding-inline-start: 0%;
    }
    .connect {
        margin: 0px 50px 0px 50px;
        padding-bottom: 0px;
    }
    .policy {
        margin: 0px 50px 0px 50px;
    }
    .aboutus-button {
        height: 36px;
        width: 190px;
        margin-bottom: 1%;
    }
    .aboutus {
        padding-top: 0%;
        margin-top: 1%;
        background-size: 100px 60px;
        width: 100px;
        height: 60px;
    }
    .music-credit {
        margin-top: 0% !important;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    .aboutus-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        list-style-type: none;
        padding-inline-start: 0%;
    }
    .connect {
        margin: 0px 50px 0px 50px;
        padding-bottom: 0px;
    }
    .policy {
        margin: 15px 50px 0px 50px;
    }
    .aboutus-button {
        height: 40px;
        width: 200px;
        margin-bottom: 10px;
    }
    .aboutus {
        padding-top: 0%;
        margin-top: 1%;
        background-size: 100px 70px;
        width: 100px;
        height: 70px;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .aboutus-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        list-style-type: none;
        padding-inline-start: 0%;
    }
    .connect {
        margin: 10px 50px 0px 50px;
        padding-bottom: 0px;
    }
    .policy {
        margin: 15px 50px 0px 50px;
    }
    .aboutus-button {
        height: 40px;
        width: 200px;
    }
    .aboutus {
        padding-top: 0%;
        margin-top: 2%;
        background-size: cover;
        width: 100px;
        height: 50px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .aboutus-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        padding-inline-start: 0%;
    }
    .connect {
        margin: 20px 50px 0px 50px;
        padding-bottom: 30px;
    }
    .policy {
        margin: 30px 50px 0px 50px;
    }
    .aboutus-button {
        height: 50px;
        width: 220px;
    }
    .aboutus {
        padding-top: 4%;
        margin-top: 4%;
        background-size: 200px 100px !important;
        width: 200px !important;
        height: 60px !important;
    }
}

.aboutus {
    background: url("/assets/images/aboutus-logo.svg") no-repeat 0;
    background-position: center;
    background-size: 100px 70px;
    width: 100px;
    height: 70px;
    display: inline-block;
}

.aboutus-background {
    /* background: url("/assets/images/BG_Mesh.svg") no-repeat 0; */
    flex: 1;
    padding: 0px;
    align-items: center;
    align-content: center;
    background-size: cover;
    height: 100vh;
}

.aboutus-action-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid #8E8E8E;
    border-radius: 25px;
    background-color: #282828;
}

.aboutus-action-button-title {
    color: #ffffff;
    font-size: 0.9rem;
    text-align: center;
    width: 230px;
    font-family: "CircularStd-Book";
    padding-top: 2.5px;
}

.aboutus-button-bg {
    background: url("/assets/images/CloseSettings.svg") no-repeat 0;
    background-size: 50px 50px;
    width: 58px;
    height: 58px;
    position: fixed;
    top: 4%;
    right: 20px;
}

.share-options {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 42px;
    margin-top: 1%;
}

.share {
    margin: 0px 7px 0px 7px;
}

.share:focus {
    outline: none;
    box-shadow: none;
}

.music-credit {
    position: fixed;
    margin-top: 1%;
    left: 0px;
    right: 0px;
    color: #ffffff;
    font-size: 0.7rem;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    font-family: "CircularStd-Book";
}