@font-face {
    font-family: "MGS75";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/MGS75.otf") format("truetype");
}

@font-face {
    font-family: "CircularStd-Book";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/CircularStd-Book.woff") format("truetype");
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "MGS75", "CircularStd-Book";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: env(safe-area-inset, 0px);
    min-height: 100vh;
    font-size: 16px;
    -webkit-text-size-adjust: none;
}

html {
    /* height: -webkit-fill-available; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}