/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .tetrate-logo {
        background-position: center;
        height: 50%;
        display: inline-block;
        margin-top: 1%;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    .tetrate-logo {
        background-position: center;
        height: 50%;
        display: inline-block;
        margin-top: 4%;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    .tetrate-logo {
        background-position: center;
        height: 50%;
        display: inline-block;
        margin-top: 1vh;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .tetrate-logo {
        background-position: center;
        height: 213px;
        display: inline-block;
        margin-top: 1vh;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .tetrate-logo {
        background-position: center;
        height: 213px;
        display: inline-block;
        margin-top: 10%;
    }
}


/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .tetrate-logo {
        background-position: center;
        height: 213px;
        display: inline-block;
        margin-top: 10%;
    }
}


/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .tetrate-logo {
        background-position: center;
        height: 213px;
        display: inline-block;
        margin-top: 10%;
    }
}


/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .tetrate-logo {
        background-position: center;
        height: 213px;
        display: inline-block;
        margin-top: 10%;
    }
}

.the-game {
    font-family: "CircularStd-Book";
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    line-height: 15px;
    color: white;
}