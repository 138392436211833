/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .intro-popover-details {
        padding: 14px 2px 7px 2px !important;
        width: 86vw !important;
    }
    .subject-container {
        margin: 5px 0px 10px 0px;
        opacity: 0.5;
        background-color: #1a1a1a;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .wc-meg-conatiner {
        margin: 0px 40px 0px 40px !important;
    }
    .wc-message {
        color: #ffffff !important;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        text-align: left;
        padding-bottom: 1%;
    }
    .details-container {
        font-size: 0.8rem !important;
        margin-left: 40px !important;
    }
    .begin-action-button {
        height: 45px !important;
        width: 160px !important;
        margin-top: 0px !important;
        background-size: 160px 50px !important;
    }
    .begin-button-title {
        font-size: 1rem !important;
        width: 160px !important;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .intro-popover-details {
        padding: 14px 2px 7px 2px !important;
        width: 86vw !important;
    }
    .subject-container {
        margin: 5px 0px 10px 0px;
        opacity: 0.5;
        background-color: #1a1a1a;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .wc-meg-conatiner {
        margin: 0px 40px 0px 40px !important;
    }
    .wc-message {
        color: #ffffff !important;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        text-align: left;
        padding-bottom: 1%;
    }
    .details-container {
        font-size: 0.8rem !important;
        margin-left: 40px !important;
    }
    .begin-action-button {
        height: 45px !important;
        width: 160px !important;
        margin-top: 0px !important;
        background-size: 160px 50px !important;
    }
    .begin-button-title {
        font-size: 1rem !important;
        width: 160px !important;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .intro-popover-details {
        padding: 14px 2px 10px 2px !important;
        width: 80vw !important;
    }
    .subject-container {
        margin: 3px 0px 8px 0px;
        opacity: 0.5;
        background-color: #1a1a1a;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .wc-meg-conatiner {
        margin: 0px 40px 0px 40px !important;
    }
    .wc-message {
        color: #ffffff !important;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        line-height: 18px;
        text-align: left;
        padding-bottom: 10px;
    }
    .begin-action-button {
        height: 45px !important;
        width: 160px !important;
        margin-top: 0px !important;
        background-size: 160px 50px !important;
    }
    .details-container {
        font-size: 0.8rem !important;
        margin-left: 40px !important;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .intro-popover-details {
        padding: 14px 2px 0px 2px !important;
        height: 72vh !important;
        width: 80vw !important;
    }
    .subject-container {
        margin: 20px 0px 15px 0px;
        opacity: 0.5;
        background-color: #1a1a1a;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .wc-meg-conatiner {
        margin: 0px 75px 15px 75px !important;
    }
    .wc-message {
        color: #ffffff !important;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        text-align: left;
        padding-bottom: 10px;
    }
    .details-container {
        font-size: 1rem;
        margin-left: 75px !important;
    }
    .begin-action-button {
        height: 55px !important;
        width: 170px !important;
        margin-top: 0px !important;
    }
    .begin-button-title {
        width: 170px !important;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .intro-popover-details {
        padding: 14px 2px 24px 2px !important;
        height: 60vh !important;
        width: 60vw !important;
    }
    .subject-container {
        margin: 10px 0px 20px 0px;
        opacity: 0.5;
        background-color: #1a1a1a;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .wc-meg-conatiner {
        margin: 20px 75px 0px 75px !important;
    }
    .wc-message {
        color: #ffffff !important;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0.5px;
        line-height: 18px;
        text-align: left;
        padding-bottom: 20px;
    }
    .details-container {
        font-size: 0.9rem !important;
        margin-left: 75px !important;
    }
    .begin-action-button {
        height: 55px;
        width: 170px;
        margin-top: 16px !important;
        background-size: 170px 55px !important;
    }
    .begin-button-title {
        /* font-size: 0.9rem !important; */
        width: 170px;
        text-align: center;
    }
}


/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .intro-popover-details {
        padding: 14px 2px 24px 2px !important;
        height: 42vh !important;
        width: 60vw !important;
    }
}


/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .intro-popover-details {
        padding: 14px 2px 24px 2px !important;
        height: 37vh !important;
        width: 60vw !important;
    }
}


/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .intro-popover-details {
        padding: 14px 2px 24px 2px !important;
        height: 35vh !important;
        width: 60vw !important;
    }
}

.title {
    width: 70px;
}

.intro-logo {
    height: 29px;
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1.5rem;
    letter-spacing: 5px;
    line-height: 29px;
    text-align: center;
}

.begin-action-button {
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 55px;
    width: 170px;
    /* background: url("/assets/images/let-begin-btn.svg") no-repeat 0; */
    border-radius: 30px;
    background: linear-gradient(0deg, #ff590d 0%, #ffc977 91%, #fffefc 100%);
    flex-direction: row;
    margin-top: 26px;
}

.begin-button-title {
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1rem;
    width: 170px;
    text-align: center;
}

.intro-popover-details {
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 60vw;
    border: 1px solid #717171;
    opacity: 0.95;
    border-radius: 12px;
    background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43), 0 10px 13px 0 rgba(35, 35, 35, 0.38);
    color: #fff;
    letter-spacing: 0.3px;
    line-height: 21px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
}

.details-container {
    display: flex;
    width: inherit;
    flex-direction: row;
    justify-content: flex-start;
    color: #ffffff !important;
    font-family: "CircularStd-Book";
    font-size: 1rem;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-align: left;
    margin-left: 75px;
}