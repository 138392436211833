/* @media only screen and (min-width: 900px) and (max-width: 1100px) {
  .stage-size {
    height: 93vh;
    width: 100vw;
  }
} */

@media only screen and (max-height: 600px) {
  .stage-size {
    height: 84vh;
    width: 100vw;
  }
}
@media only screen and (min-height: 600px) {
  .stage-size {
    height: 92vh;
    width: 100vw;
  }
}

/* @media only screen and (min-width: 1100px) {
  .stage-size {
    height: 92vh;
    width: 100vw;
  }
}

@media only screen and (min-width: 600px) and (max-width: 750px) {
  .stage-size {
    height: 90vh;
    width: 100vw;
  }
}

@media only screen and (min-width: 750px) and (max-width: 900px) {
  .stage-size {
    height: 91vh;
    width: 100vw;
  }
} */

.mxPopupMenu {
  background: #440c5f;
  position: absolute;
  border: 2px solid #b778ff;
  padding: 3px;
  margin-left: 25px;
}

body table.mxPopupMenu {
  border-collapse: collapse;
  margin: 0px;
}

body tr.mxPopupMenuItem {
  color: white;
  cursor: default;
}

body td.mxPopupMenuItem {
  padding: 10px 40px 10px 10px;
  font-family: Arial;
  font-size: 11pt;
}

body td.mxPopupMenuIcon {
  background-color: #440c5f;
  padding: 15px;
}

body tr.mxPopupMenuItemHover {
  color: white;
}

table.mxPopupMenu hr {
  margin: 0px;
}

table.mxPopupMenu tr {
  font-size: 4pt;
}

.flow-initial {
  stroke: #ff590d;
  stroke-width: 10;
  stroke-dasharray: 0 52;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.flow {
  stroke-width: 3;
  stroke-dasharray: 10 80;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.flow-slow {
  stroke-width: 2;
  stroke-dasharray: 10 80;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.flow-reverse {
  stroke-width: 3;
  stroke-dasharray: 10 80;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.graph-container {
  background: url("/assets/images/bg_with grid.svg");
  background-size: cover;
}

@keyframes dash {
  to {
    stroke-dashoffset: -80;
  }
}
@keyframes dash-reverse {
  to {
    stroke-dashoffset: 80;
  }
}

@keyframes round {
  to {
    stroke-dashoffset: -50;
  }
}

.dotted-line {
  stroke-width: 1;
  /* stroke-dasharray: 10 20; */
  stroke-linejoin: round;
  stroke-linecap: round;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: -80;
  }
}
