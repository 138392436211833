/* Extra small devices (phones, 600px and down) */

.info-bg {
    background: url("/assets/images/settings-circle.svg") no-repeat 0;
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 30px;
    left: 30px;
}

.button-bg {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    /* background: url("/assets/images/settings-circle.svg") no-repeat 0;
    background-size: 50px 50px; */
    height: 50px;
    /* width: 50px; */
    position: fixed;
    bottom: 15px;
    right: 25px;
}

@media only screen and (max-width: 600px) {
    .mission-accomplished {
        margin-top: 0px !important;
    }
    .button-bg {
        bottom: 2%;
        right: 25px;
    }
    .info-bg {
        bottom: 2%;
        left: 25px;
    }
    .img {
        height: 80px;
        margin-top: 0px;
    }
    .mission-container {
        padding-top: 1%;
    }
    .learn-more {
        bottom: 2% !important;
    }
    .share-button {
        margin: 0px 0px 0px 5px;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .mission-accomplished {
        margin-top: 0px !important;
    }
    .button-bg {
        bottom: 17%;
        right: 30px;
        transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 40%);
    }
    .info-bg {
        bottom: 17%;
        left: 30px;
        transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 40%);
    }
    .img {
        height: 80px;
        margin-top: 0px;
    }
    .mission-container {
        padding-top: 1%;
    }
    .learn-more {
        bottom: 17% !important;
        transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 40%);
    }
    .share-button {
        margin: 0px 0px 0px 15px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .button-bg {
        bottom: 17%;
        right: 50px;
        transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 40%);
    }
    .info-bg {
        bottom: 17%;
        left: 50px;
        transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 40%);
    }
    .learn-more {
        bottom: 17% !important;
        transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 40%);
    }
    .share-button {
        margin: 0px 0px 0px 15px;
    }
    .img {
        height: 80px;
        margin-top: 10px;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .button-bg {
        bottom: 20%;
        right: 50px;
    }
    .info-bg {
        bottom: 20%;
        left: 50px;
    }
    .learn-more {
        bottom: 10% !important;
    }
    .share-button {
        margin: 0px 0px 0px 15px;
    }
    .img {
        height: 100px;
        margin-top: 10px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .button-bg {
        bottom: 5%;
        right: 50px;
    }
    .info-bg {
        bottom: 5%;
        left: 50px;
    }
    .mission-container {
        padding-top: 12%;
    }
    .learn-more {
        bottom: 5% !important;
    }
    .share-button {
        margin: 0px 0px 0px 15px;
    }
    .img {
        height: 100px;
        margin-top: 10px;
    }
}


/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .mission-container {
        padding-top: 20%;
    }
    .button-bg {
        bottom: 5%;
        right: 50px;
    }
    .info-bg {
        bottom: 5%;
        left: 50px;
    }
    .learn-more {
        bottom: 5% !important;
    }
    .share-button {
        margin: 0px 0px 0px 15px;
    }
}


/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .mission-container {
        padding-top: 20%;
    }
    .button-bg {
        bottom: 5% !important;
        right: 50px;
    }
    .info-bg {
        bottom: 5% !important;
        left: 50px;
    }
    .learn-more {
        bottom: 5% !important;
    }
    .share-button {
        margin: 0px 0px 0px 15px;
    }
}


/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .mission-container {
        padding-top: 20%;
    }
    .button-bg {
        bottom: 5% !important;
        right: 50px;
    }
    .info-bg {
        bottom: 5% !important;
        left: 50px;
    }
    .learn-more {
        bottom: 5% !important;
    }
    .share-button {
        margin: 0px 0px 0px 15px;
    }
}

.mission-accomplished {
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1.7rem;
    letter-spacing: 2.83px;
    line-height: 39px;
    text-align: center;
    margin-top: 10px;
}

.you-have-completed-t {
    height: 14px;
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1rem;
    letter-spacing: 0.16px;
    line-height: 14px;
    text-align: center;
    margin-top: 8px;
    width: 100%;
}

.misson-background {
    background: #191c1d;
    align-items: center;
    align-content: center;
    background-size: cover;
    height: 100vh;
    position: fixed;
    top: 0;
}

.mission-button {
    background: url("/assets/images/connect.svg") no-repeat 0;
    background-position: 50%;
    background-size: 40px 40px;
    height: 50px;
    width: 50px;
}

.mission-info-button {
    background: url("/assets/images/badge_info.svg") no-repeat 0;
    background-position: 50%;
    background-size: 30px 30px;
    height: 50px;
    width: 50px;
}

.mission-tsb {
    /* height: 100px; */
    width: 100px;
    margin-left: 20px;
    margin-right: 20px;
}

.image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    margin-top: 1%;
}

.mission-badge-title {
    opacity: 0.9;
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 1.4rem;
    text-align: center;
    width: 110px !important;
}

.mission-badge-text {
    height: 12px;
    color: #8e8e8e;
    font-family: "CircularStd-Book";
    font-size: 0.6rem;
    letter-spacing: 0.52px;
    line-height: 11.12px;
    text-align: center;
    margin-top: 0px;
}

.mission-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-items: center;
}

.mission-screen {
    height: 100vh;
    width: 100vw;
    background: url("/assets/images/BG_Mesh.svg") no-repeat;
    background-size: cover;
}

.share-button:focus {
    outline: none;
    box-shadow: none;
}

.learn-more {
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 180px;
    border-radius: 30px;
    background: linear-gradient(0deg, #FF590D 0%, #FFC977 91%, #FFFEFC 100%);
    background-position: center;
    flex-direction: row;
    position: fixed;
    bottom: 5%;
}

.learn-more-title {
    color: #ffffff;
    font-size: 1.4rem;
    width: 180px;
    text-align: center;
    font-family: "CircularStd-Book";
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 25px;
    padding-top: 3.5px;
}