/* Extra small devices (phones, 600px and down) */
.user-action-img {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .user-action-img {
    height: 52px;
    width: 47px;
    margin-top: 0px;
  }
  .correct-icon {
    height: 42px;
    width: 42px;
    margin-top: 5px;
  }
  .user-action-button {
    width: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    height: 90% !important;
    font-size: 0.8rem;
  }
  .user-action-box {
    padding-top: 2% !important;
    height: 43vh !important;
    width: 55vw !important;
  }
  .user-action-div {
    padding-bottom: 2% !important;
  }
  .user-action-box:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -16px;
    right: 94%;
    height: 17px;
    border-right: 26px solid #3d3d3d;
    background: #191c1d;
    border-bottom-right-radius: 75px 59px;
  }
  .user-action-box:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -18px;
    right: 97%;
    width: 13px;
    height: 20px;
    background: rgb(26, 26, 26, 0.7);
    border-bottom-right-radius: 40px 50px;
  }
  .drop-text {
    font-size: 0.9rem;
  }
  .speech-bubble {
    display: inline-block;
    min-width: 100px;
    max-width: 40%;
    padding: 1%;
    position: absolute;
    bottom: 12%;
    left: 10%;
    border: 0px solid #717171;
    opacity: 0.95;
    border-radius: 15px;
    color: #fff;
    background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43),
      0 10px 13px 0 rgba(35, 35, 35, 0.38);
  }
  .speech-bubble:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -14px;
    left: 0%;
    height: 14px;
    border-right: 12px solid #717171;
    opacity: 0.4;
    background: #191c1d;
    border-bottom-right-radius: 65px 42px;
    right: 78%;
  }
  .user-action {
  }
  .hint {
    /* height: 29px;
    width: 52px; */
    height: 40px;
    /* width: 12%; */
    z-index: 2;
  }
  .hint-text {
    text-align: left;
    padding: 3%;
    color: #fff;
    font-size: 0.9rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .user-action-img {
    height: 52px;
    width: 47px;
    margin-top: 0px;
  }
  .correct-icon {
    height: 42px;
    width: 42px;
    margin-top: 5px;
  }
  .user-action-button {
    width: 40% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    height: 90% !important;
    font-size: 0.8rem;
  }
  .user-action-box {
    padding-top: 2% !important;
    height: 50vh !important;
    width: 63% !important;
  }
  .user-action-div {
    padding-bottom: 2% !important;
  }
  .user-action-box:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -16px;
    right: 94%;
    height: 17px;
    border-right: 26px solid #3d3d3d;
    background: #191c1d;
    border-bottom-right-radius: 75px 59px;
  }
  .user-action-box:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -18px;
    right: 97%;
    width: 13px;
    height: 20px;
    background: rgb(26, 26, 26, 0.7);
    border-bottom-right-radius: 40px 50px;
  }
  .drop-text {
    font-size: 0.9rem;
  }
  .speech-bubble {
    display: inline-block;
    min-width: 100px;
    max-width: 40%;
    padding: 1%;
    position: absolute;
    bottom: 12%;
    left: 10%;
    border: 0px solid #717171;
    opacity: 0.95;
    border-radius: 15px;
    color: #fff;
    background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43),
      0 10px 13px 0 rgba(35, 35, 35, 0.38);
  }
  .speech-bubble:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -14px;
    left: 0%;
    height: 14px;
    border-right: 12px solid #717171;
    opacity: 0.4;
    background: #191c1d;
    border-bottom-right-radius: 65px 42px;
    right: 78%;
  }
  .user-action {
  }
  .hint {
    /* height: 29px;
    width: 52px; */
    height: 40px;
    /* width: 12%; */
    z-index: 2;
  }
  .hint-text {
    text-align: left;
    padding: 3%;
    color: #fff;
    font-size: 0.9rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .user-action-button {
    font-size: 0.9rem;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    height: 100% !important;
  }
  .user-action-img {
    height: 60px;
    width: 55px;
    margin-top: 0px;
  }
  .correct-icon {
    height: 50px;
    width: 50px;
    margin-top: 5px;
  }
  .hintbar {
    position: absolute;
    bottom: 3%;
    /* margin: 2%; */
    display: flex;
    align-items: center;
    height: 20%;
    margin-left: 2%;
  }
  .speech-bubble {
    display: inline-block;
    min-width: 100px;
    max-width: 40%;
    padding: 1%;
    position: absolute;
    bottom: 16% !important;
    left: 11%;
    border: 0px solid #717171;
    opacity: 0.95;
    border-radius: 15px;
    color: #fff;
    background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43),
      0 10px 13px 0 rgba(35, 35, 35, 0.38);
  }
  .speech-bubble:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -13px;
    right: 80%;
    height: 13px;
    border-right: 13px solid #717171;
    opacity: 0.4;
    background: rgb(26, 26, 26, 0.5);
    left: 0%;
    border-bottom-right-radius: 65px 42px;
  }
  .user-action {
  }
  .drop-text {
    padding-top: 0%;
    font-size: 1rem;
  }
  .blank-hintbar {
    height: 7.2vh;
  }
  .hiddend {
    width: 50%;
    margin-left: 2%;
    z-index: 2;
  }
  .close-icon {
    font-size: 1rem;
    cursor: pointer;
    width: 12%;
  }
  .hint {
    height: 40px;
    z-index: 2;
  }
  .hint-text {
    text-align: left;
    padding: 3%;
    color: white;
    opacity: 0.7;
    font-size: 1rem;
  }
  .user-action-box {
    left: 11% !important;
    bottom: 18% !important;
    height: 46vh !important;
    width: 55vw !important;
  }
  .user-action-box:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -16px;
    right: 94%;
    height: 17px;
    border-right: 26px solid #3d3d3d;
    background: #191c1d;
    border-bottom-right-radius: 75px 59px;
  }
  .user-action-box:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -18px;
    right: 97%;
    width: 13px;
    height: 20px;
    background: rgb(26, 26, 26, 0.7);
    border-bottom-right-radius: 40px 50px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .user-action-button {
    font-size: 1rem;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    height: 90% !important;
  }
  .user-action-img {
    height: 90px;
    width: 85px;
    margin-top: 0px;
  }
  .correct-icon {
    height: 78px;
    width: 78px;
    margin-top: 7px;
  }
  .hintbar {
    position: absolute;
    bottom: 3%;
    /* margin: 2%; */
    display: flex;
    align-items: center;
    height: 20%;
    margin-left: 2%;
  }
  .user-action {
  }
  .blank-hintbar {
    height: 7.2vh;
  }
  .hiddend {
    width: 50%;
    margin-left: 2%;
    z-index: 2;
  }
  .close-icon {
    font-size: 1rem;
    cursor: pointer;
    width: 12%;
  }
  .hint {
    height: 40px;
    z-index: 2;
  }
  .drop-text {
    padding-top: 2%;
    font-size: 1.1rem;
  }
  .hint-text {
    text-align: left;
    padding: 3%;
    color: white;
    opacity: 0.7;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    line-height: 18px;
  }
  .user-action-box {
    left: 11% !important;
    bottom: 15% !important;
  }
  .user-action-box:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -16px;
    right: 94%;
    height: 17px;
    border-right: 26px solid #3d3d3d;
    background: #191c1d;
    border-bottom-right-radius: 75px 59px;
  }
  .user-action-box:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -18px;
    right: 97%;
    width: 13px;
    height: 20px;
    background: rgb(26, 26, 26, 0.7);
    border-bottom-right-radius: 40px 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  .dropzone-top-img {
    position: absolute;
    top: -11%;
    left: 5%;
  }
  .user-action-img {
    height: 90px;
    width: 85px;
    margin-top: 0px;
  }
  .correct-icon {
    height: 78px;
    width: 78px;
    margin-top: 7px;
  }
  .user-action-button {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    font-size: 1rem;
    height: 95% !important;
    justify-content: flex-start;
  }
  .user-action-box {
    height: 34vh !important;
    width: 45vw !important;
  }
  .user-action-box:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -20px;
    right: 96%;
    height: 20px;
    border-right: 47px solid #3d3d3d;
    background: #191c1d;
    border-bottom-right-radius: 75px 59px;
  }
  .user-action-box:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -20px;
    right: 98%;
    width: 30px;
    height: 20px;
    background: rgb(26, 26, 26, 0.7);
    border-bottom-right-radius: 40px 50px;
  }
  .drop-text {
    padding-top: 2%;
    font-size: 1.1rem;
  }
  .hintbar {
    position: absolute;
    bottom: 0%;
    display: flex;
    align-items: center;
    height: 20%;
    margin-left: 2%;
  }
  .speech-bubble {
    display: inline-block;
    min-width: 150px;
    max-width: 30%;
    padding: 1%;
    position: absolute;
    bottom: 11% !important;
    left: 8%;
    border: 0px solid #717171;
    opacity: 0.95;
    border-radius: 15px;
    color: #fff;
    background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43),
      0 10px 13px 0 rgba(35, 35, 35, 0.38);
  }
  .speech-bubble:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -14px;
    right: 85%;
    height: 14px;
    border-right: 12px solid #717171;
    opacity: 0.4;
    background: rgb(26, 26, 26, 0.7);
    border-bottom-right-radius: 75px 59px;
    left: 3%;
  }
  .user-action {
  }
  .blank-hintbar {
    height: 7.2vh;
  }
  .hint-text {
    text-align: left;
    color: #fff;
    font-size: 1.1rem;
  }
  .app-tour-hint {
    width: 30%;
    position: absolute;
    border: 4px solid orange;
    height: 6%;
    border-radius: 50px;
    margin-right: 1%;
    margin-left: 1%;
    background: transparent;
  }
}

/* iPhone XR */

@media only screen and (device-width: 896px) and (device-height: 414px) and (-webkit-device-pixel-ratio: 2) {
  .user-action-button {
    height: 90%;
  }
}

/* iPhone XS */

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  /* .user-action-box {
        left: 11% !important;
        bottom: 18% !important;
    } */
  .user-action-button {
    height: 90%;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .user-action-box {
    height: 25vh !important;
    width: 52vw !important;
    left: 10% !important;
  }
  .speech-bubble {
    bottom: 13% !important;
  }
}

/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .user-action-box {
    height: 24vh !important;
    width: 52vw !important;
    left: 10% !important;
  }
  .speech-bubble {
    bottom: 13% !important;
  }
}

/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .user-action-box {
    height: 20vh !important;
    left: 10% !important;
  }
  .speech-bubble {
    left: 11% !important;
  }
}

.hideStage {
  display: none;
}

.tour-ovel {
  height: 50px;
  z-index: 2;
  padding-top: 15px;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 10s ease-in-out;
}

.incorrect-name {
  margin-top: 3%;
  color: #8e8e8e;
}

.overlay {
  height: 100vh;
}

.user-action-div {
  display: flex;
  width: inherit;
  justify-content: center;
  text-align: center;
  position: fixed;
  padding-bottom: 4%;
  bottom: 0px;
  left: auto;
  right: auto;
}

.user-action-box {
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  padding: 2%;
  text-align: initial;
  flex-direction: column;
  position: absolute;
  bottom: 15%;
  left: 9%;
  height: 40vh;
  width: 45vw;
  border: 0px solid #717171;
  border-radius: 19px;
  color: #fff;
  background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
  box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43),
    0 10px 13px 0 rgba(35, 35, 35, 0.38);
}

.icon-name {
  margin-top: 2% !important;
  vertical-align: text-top;
}

.play-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  right: 20px !important;
  position: absolute;
  right: 3%;
  bottom: 6%;
}

.correct-icon {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 1);
  border-radius: 50%;
}
