.tetrate-orange-logo {
    /* background: url("/assets/images/tetrate_orange_logo.svg") no-repeat 0; */
    width: 107px;
    height: 10px;
    display: inline-block;
}

.land-screen-background {
    background: url("/assets/images/BG_Mesh.svg") no-repeat 0;
    flex: 1;
    padding: 0px;
    align-items: center;
    align-content: center;
    background-size: cover;
    height: 100vh;
}

.copyright-message {
    position: fixed;
    bottom: 6%;
    left: 0px;
    right: 0px;
    color: #ffffff;
    font-size: 0.8rem;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    font-family: "CircularStd-Book";
}