/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .topbar {
        height: 9vh;
        padding: 5px;
        justify-content: center;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    .topbar {
        height: 9vh;
        padding: 6px;
        justify-content: center;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    .topbar {
        height: 9vh;
        padding: 6px;
        justify-content: center;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .topbar {
        height: 7vh;
        padding: 7px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .topbar {
        height: 8vh;
        padding: 7px;
    }
}

@media only screen and (min-width: 1350px) {
    .topbar {
        height: 6vh;
        padding: 7px;
    }
}

.topbar {
    display: flex;
    background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43), 0 10px 13px 0 rgba(35, 35, 35, 0.38);
    align-items: center;
}

.hideStage {
    display: none;
}