.close-button {
    background: url("/assets/images/CloseSettings.svg") no-repeat 0;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    position: fixed;
    top: 4%;
    right: 20px;
    -ms-transform: skew(20deg, 0deg);
    /* IE 9 */
    transform: skew(20deg, 0deg);
}


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .outerDiv {
        padding-top: 0% !important;
    }
    .badge-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .badge-details-list {
        width: 80% !important;
    }
    .badge-i {
        position: absolute;
        top: 14%;
        z-index: 2;
        margin-left: -16%;
    }
    .badge-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 7%;
        text-transform: uppercase;
        text-align: left;
        padding-left: 3%;
    }
    .sub-text-desc {
        margin-top: 0px;
        text-align: initial;
        opacity: 0.5;
        color: #9E9E9E;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.3px;
        line-height: 18px;
        padding-left: 1%;
    }
    .divider-line {
        bottom: -20%;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    .outerDiv {
        padding-top: 0% !important;
    }
    .badge-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .badge-i {
        position: absolute;
        top: 14%;
        z-index: 2;
        margin-left: -13%;
    }
    .badge-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 7%;
        text-transform: uppercase;
        text-align: left;
        padding-left: 3%;
    }
    .sub-text-desc {
        margin-top: 0px;
        font-size: 1rem;
        text-align: initial;
        opacity: 0.5;
        color: #9E9E9E;
        font-family: "CircularStd-Book";
        font-size: 0.9rem;
        letter-spacing: 0.3px;
        line-height: 18px;
        padding-left: 1%;
    }
    .divider-line {
        bottom: -20%;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    .outerDiv {
        padding-top: 0% !important;
    }
    .badge-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .badge-i {
        position: absolute;
        top: 14%;
        z-index: 2;
        margin-left: -12%;
    }
    .badge-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 1.1rem;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 7%;
        text-transform: uppercase;
        text-align: left;
        padding-left: 3%;
    }
    .sub-text-desc {
        margin-top: 0px;
        font-size: 1rem;
        text-align: initial;
        opacity: 0.5;
        color: #9E9E9E;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0.3px;
        line-height: 18px;
        padding-left: 1%;
    }
    .divider-line {
        bottom: -20%;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .outerDiv {
        padding-top: 0% !important;
    }
    .badge-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .badge-i {
        position: absolute;
        top: 14%;
        z-index: 2;
        margin-left: -10%;
    }
    .badge-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 1.1rem;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 7%;
        text-transform: uppercase;
        text-align: left;
        padding-left: 3%;
    }
    .sub-text-desc {
        margin-top: 0px;
        font-size: 1rem;
        text-align: initial;
        opacity: 0.5;
        color: #9E9E9E;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0.3px;
        line-height: 18px;
        padding-left: 1%;
    }
    .divider-line {
        bottom: -20%;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .outerDiv {
        padding-top: 5% !important;
    }
    .close-button {
        background-size: 40px 40px;
        width: 40px;
        height: 40px;
    }
    .badge-details-list {
        margin-top: 0% !important;
    }
    .badge-icon-div {
        width: 50px;
        margin-top: 11%;
    }
    .badge-i {
        position: absolute;
        top: 14%;
        z-index: 2;
        margin-left: -7%;
    }
    .badge-name {
        color: #E8E8E8;
        font-family: "CircularStd-Book";
        font-size: 1.1rem;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 7%;
        text-transform: uppercase;
        text-align: left;
        padding-left: 3%;
    }
    .sub-text-desc {
        margin-top: 0px;
        font-size: 1rem;
        text-align: initial;
        opacity: 0.5;
        color: #9E9E9E;
        font-family: "CircularStd-Book";
        font-size: 1rem;
        letter-spacing: 0.3px;
        line-height: 18px;
        padding-left: 1%;
    }
    .divider-line {
        bottom: -10%;
    }
}


/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .outerDiv {
        padding-top: 15% !important;
        right: 20% !important;
    }
    .badge-i {
        margin-left: -11%;
    }
}


/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .outerDiv {
        padding-top: 15% !important;
        right: 20% !important;
    }
    .badge-i {
        margin-left: -11%;
    }
}


/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .outerDiv {
        padding-top: 15% !important;
        right: 20% !important;
    }
    .badge-i {
        margin-left: -9%;
    }
}

.divider-line {
    position: fixed;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.3);
    left: 5%;
    right: 0;
}

.outerDiv {
    padding-top: 5%;
    background: linear-gradient(180deg, #656565 0%, #3D3D3D 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43), 0 10px 13px 0 rgba(35, 35, 35, 0.38);
    position: absolute;
    top: 0%;
    left: 15%;
    right: 15%;
    height: 100vh;
    -ms-transform: skew(-20deg, 0deg);
    /* IE 9 */
    transform: skew(-20deg, 0deg);
    /* Standard syntax */
}

.innerDiv {
    top: 0%;
    left: 0%;
    width: 90%;
    -ms-transform: skew(20deg, 0deg);
    /* IE 9 */
    transform: skew(20deg, 0deg);
    /* Standard syntax */
}

.badge-details-screen {
    flex-direction: column;
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    background: radial-gradient(circle, rgba(27, 27, 27, 0.74) 0%, rgba(27, 27, 27, 0.74) 100%);
    z-index: 2;
    color: #ffffff;
    z-index: 3;
}

.badge-div-list {
    display: flex;
    margin-top: 1%;
    flex-direction: row;
}

.badge-details-list {
    text-align: initial;
    width: 60%;
    margin-top: 2%;
}