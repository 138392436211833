/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {}


/* ----------- iPad 3, 4 and Pro 9.7 Portrait and Landscape " ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .retry-msg {
        bottom: 80px !important;
    }
}


/* ----------- iPad Pro 10.5" Portrait and Landscape----------- 
 Declare the same value for min- and max-width to avoid colliding with desktops
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .retry-msg {
        bottom: 80px !important;
    }
}


/* Landscape 
Declare the same value for min- and max-width to avoid colliding with desktops 
Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .retry-msg {
        bottom: 80px !important;
    }
}

.internet-action-button {
    position: fixed;
    bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 50px;
    width: 180px;
    /* background: url("/assets/images/retry.svg") no-repeat 0; */
    border-radius: 30px;
    background: linear-gradient(0deg, #DD5044 0%, #FF9696 95%, #FFFEFC 100%);
    background-position: center;
    flex-direction: row;
    background-size: 180px 50px;
}

.internet-button-title {
    color: #ffffff;
    font-size: 0.9rem;
    width: 180px;
    text-align: center;
    font-family: "CircularStd-Book";
    font-size: 0.9rem;
    letter-spacing: 0;
    line-height: 25px;
    padding-top: 3.5px;
}

.retry-msg {
    color: #FFFFFF;
    font-family: "CircularStd-Book";
    font-size: 1rem;
    text-align: center;
    position: fixed;
    bottom: 75px;
}

.internet-background {
    background: url("/assets/images/BG_Mesh.svg") no-repeat 0;
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-items: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

.internet-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-items: center;
    padding-top: 5%;
    background: url("/assets/images/BG_Mesh.svg") no-repeat;
    background-size: cover;
    align-content: center;
}

.internet-screen {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    background: radial-gradient(circle, #434748 0%, #202426 100%);
    z-index: 3;
    color: #ffffff;
    display: flex;
    flex-direction: column;
}