.badge-screen {
  background: linear-gradient(180deg, #656565 0%, #3d3d3d 100%);
  color: white;
  z-index: 2;
  border-radius: 25px;
  padding: 1.5% 0% 1.5% 0%;
  width: 54vw;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
}

.badge-screen-container {
  display: flex;
  height: fit-content;
  align-items: center;
}

.badge-earned-name {
  width: 40%;
  height: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transform: translate(0%, -20%);
  -webkit-transform: translate(0%, -20%);
  -moz-transform: translate(0%, 20%);
}

.badge-earned-details {
  width: 60%;
  text-align: left;
  /* height: 80%; */
  display: flex;
  align-items: center;
}

.awesome-text {
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
}

.sub-title {
  margin-top: 0% !important;
  font-size: 1.0625rem !important;
  margin-bottom: 1rem;
}

.sub-text {
  font-size: 1rem;
  margin-bottom: 2%;
}

.badge-earned-steps {
  font-size: 0.875rem;
  padding-left: 6%;
}

.badge-icon {
  z-index: 2;
  position: relative;
  height: 90%;
  width: 90%;
}

.badge-title {
  font-size: 1.25rem;
  text-transform: uppercase;
  /* margin-bottom: 2%; */
  font-weight: 600;
}

.badge-text {
  color: #b8b8b8;
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-bottom: 10%;
}

.badge-earned-steps > li {
  margin-bottom: 1%;
}

.lines-container {
  display: flex;
  justify-content: center;
}

.lines {
  margin: 3px;
  border: 1px solid #3d3d3d;
  border-radius: 15px;
  background-color: #3d3d3d;
  width: 1px;
  float: left;
  height: 40px;
}

.ok-button-badge-earned {
  height: 30px;
  width: 70px;
  cursor: pointer;
  border-radius: 30px;
  background: linear-gradient(0deg, #ff590d 0%, #ffc977 91%, #fffefc 100%);
  flex-direction: row;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}

.badge-close-button {
  background: url("/assets/images/CloseSettings.svg") no-repeat 0;
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translate(100%, 100%);
  -webkit-transform: translate(100%, 100%);
  -moz-transform: translate(100%, 100%);
  cursor: pointer;
}

/* Landscape */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  /* .badge-screen {
    width: 70vw;
    height: 60vh;
    padding: 2% 0%;
  } */
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Landscape */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  /* .badge-screen {
    width: 70vw;
    height: 60vh;
  } */
}

/* ----------- iPhone X ----------- */

/* Landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
