/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .settings-update-options-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 50px 50px 50px;
        padding-bottom: 30px;
        list-style-type: none;
        padding-inline-start: 0%;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    .settings-update-options-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px;
        padding-bottom: 30px;
        list-style-type: none;
        padding-inline-start: 0%;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
    .settings-update-options-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 60px;
        padding-bottom: 30px;
        list-style-type: none;
        padding-inline-start: 0%;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
    .settings-update-options-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 60px;
        padding-bottom: 30px;
        list-style-type: none;
        padding-inline-start: 0%;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .settings-update-options-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 80px 50px 50px 50px;
        padding-bottom: 30px;
        list-style-type: none;
        padding-inline-start: 0%;
    }
}

.settings-update-background {
    background: url("/assets/images/BG_Mesh.svg") no-repeat 0;
    flex: 1;
    padding: 0px;
    align-items: center;
    align-content: center;
    background-size: cover;
    height: 100vh;
}

.settings-update-action-button {
    cursor: pointer;
    /* background: url("/assets/images/btn-bg.svg") no-repeat 0; */
    border-radius: 30px;
    background: linear-gradient(0deg, #FF590D 0%, #FFC977 91%, #FFFEFC 100%);
    height: 54.76px;
    width: 230px;
    border-radius: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.settings-update-button-title {
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    width: 230px;
    font-family: "CircularStd-Book";
}

.settings-button-bg {
    background: url("/assets/images/CloseSettings.svg") no-repeat 0;
    background-size: 50px 50px;
    width: 58px;
    height: 58px;
    position: fixed;
    top: 4%;
    right: 20px;
}

.settings {
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 25px;
    letter-spacing: 5px;
    line-height: 29px;
    text-align: center;
    padding-top: 4%;
}