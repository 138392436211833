.landscape-msg {
    margin-top: 0px;
    color: #FFFFFF;
    font-family: "CircularStd-Book";
    font-size: 1rem;
    letter-spacing: 0.5px;
    line-height: 18px;
    margin-left: 10px;
    margin-right: 10px;
}

.landscape-details {
    background: linear-gradient(180deg, #656565 0%, #3D3D3D 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43), 0 10px 13px 0 rgba(35, 35, 35, 0.38);
    position: absolute;
    top: 25%;
    left: 10%;
    right: 10%;
    height: 40%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.landscape-screen {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    background: radial-gradient(circle, rgba(27, 27, 27, 0.74) 0%, rgba(27, 27, 27, 0.74) 100%);
    z-index: 3;
    color: #ffffff;
}

.fullscreen-action-button {
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 55px;
    width: 190px;
    border-radius: 30px;
    background: linear-gradient(0deg, #FF590D 0%, #FFC977 91%, #FFFEFC 100%);
    flex-direction: row;
    margin-top: 26px;
    align-self: center;
    background-size: 190px 55px;
}

.fullscreen-button-title {
    color: #ffffff;
    font-family: "CircularStd-Book";
    font-size: 0.9rem;
    width: 190px;
    text-align: center;
}