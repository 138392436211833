/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .permission-detail {
        width: 80vw;
        padding: 2% 3% 1% 3%;
    }
    .granting-permissions {
        padding-left: 2% !important;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .permission-detail {
        width: 80vw;
        padding: 2% 3% 2% 3%;
    }
    .granting-permissions {
        padding-left: 2% !important;
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .permission-detail {
        width: 70vw;
        padding: 2% 3% 2% 3%;
    }
}


/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .permission-detail {
        width: 60vw;
        padding: 2% 3% 2% 3%;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
    .permission-detail {
        width: 60vw;
        padding: 2% 3% 2% 3%;
    }
}

.permission-detail {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.permissions-screen {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    background: radial-gradient(circle, rgba(27, 27, 27, 0.74) 0%, rgba(27, 27, 27, 0.74) 100%);
    z-index: 3;
    color: #ffffff;
}

.platform {
    height: 140px;
    color: #FFFFFF;
    font-family: "CircularStd-Book";
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    line-height: 20px;
}

.granting-permissions {
    color: #FFFFFF;
    font-family: "CircularStd-Book";
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    line-height: 15px;
    text-align: left;
}

.rectangle-bg {
    height: 26px;
    opacity: 0.5;
    background-color: #1A1A1A;
    margin-top: 15px;
    padding-top: 3px !important;
    padding-bottom: 0px !important;
    margin-bottom: 5px;
}

.permissions-details-list {
    width: 96%;
    display: flex;
    flex-direction: row;
    padding: 1.5%;
    align-items: center;
}

.permissions-div-list {
    display: flex;
    margin-top: 1%;
    flex-direction: column;
    padding: 5%;
}

.permissions-name {
    color: white;
    font-family: "CircularStd-Book";
    font-size: 1.1rem;
    letter-spacing: 0;
    line-height: 18px;
    text-align: left;
    width: 50%;
}

.permissions-msg {
    color: white;
    font-family: "CircularStd-Book";
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-align: left;
    width: 45%;
}

.permission-img {
    margin-right: 1%;
    z-index: 1;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-image: linear-gradient(180deg, #ffc317 0%, #de4d00 100%);
}

.permission-denied-img {
    margin-right: 1%;
    z-index: 1;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-image: radial-gradient(circle, #C0C0C0 0%, #202426 100%);
}

.check-img {
    padding-top: 5px;
}

.permission-detail {
    background: linear-gradient(180deg, #656565 0%, #3D3D3D 100%);
    box-shadow: inset 1px 2px 0 0 rgba(99, 99, 99, 0.43), 0 10px 13px 0 rgba(35, 35, 35, 0.38);
    position: absolute;
    border-radius: 8px;
}